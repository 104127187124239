import React from "react";

import joinClassNames from "../../../helpers/joinClassNames";

const ServiceContent = ({className, children}) => {
    return <div className={joinClassNames('card-content', className)}>
        <div className="content">
            {children}
        </div>
    </div>
}

export default ServiceContent;
