import React from "react";
import PropTypes from "prop-types";

const Service = ({header, image, children}) => {
    return <div className="card">
        <div className="card-content">
            <p className="title is-align-items-center is-flex">
                <figure className="image is-64x64 is-pulled-left mr-4">
                    <img src={image} alt="Obrazek usługi"/>
                </figure>
                {header}
            </p>
        </div>
        {children}
    </div>
}

Service.propTypes = {
    header: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default Service;
