import React from "react";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import {Link} from "gatsby";

import Footer from "../components/Layout/Footer/Footer";
import Layout from "../components/Layout/layout";
import Menu from "../components/Layout/Menu/Menu";
import Service from "../components/Layout/Service/Service";
import ServiceContent from "../components/Layout/Service/ServiceContent";
import PageTitle from "../components/Layout/Page/PageTitle";
import Question from "../components/Layout/Page/Question";

import "./zakres-uslug.scss";

import brake from "../images/service/brake.svg";
import wheels from "../images/service/wheels.svg";
import steeringWheel from "../images/service/steering-wheel.svg";
import engine from "../images/service/engine.svg";
import computerEngine from "../images/service/computer-engine.svg";
import lights from "../images/service/lights.svg";
import driveSystem from "../images/service/drive-system.svg";
import electronic from "../images/service/electronic.svg";
import SchemaOrg from "../components/SEO/SchemaOrg";

export default function ServicesPage() {
    return <Layout>
        <GatsbySeo
            title='Zakres usług'
            description='Zajmujemy się komputerową diagnostyką silników, geometrią kół, bieżącymi wymianami części eksploatacyjnych oraz usterkami eletroniczno - elektrycznymi.'
            canonical='https://serwis-olaszewski.pl/zakres-uslug'
            openGraph={{
                title: 'Zakres usług | Auto Serwis Olaszewski',
                url: 'https://serwis-olaszewski.pl/zakres-uslug',
                description: 'Zajmujemy się komputerową diagnostyką silników, geometrią kół, bieżącymi wymianami części eksploatacyjnych oraz usterkami eletroniczno - elektrycznymi.'
            }}
            twitter={{
                title: 'Zakres usług | Auto Serwis Olaszewski',
                description: 'Zajmujemy się komputerową diagnostyką silników, geometrią kół, bieżącymi wymianami części eksploatacyjnych oraz usterkami eletroniczno - elektrycznymi.'
            }}
        />

        <SchemaOrg/>

        <Menu/>

        <main className="has-margin-top">
            <PageTitle>Zakres usług</PageTitle>

            <section className="services mb-5">
                <div className="container">
                    <div className="columns is-multiline">
                        <div className="column is-one-third">
                            <Service header="Silnik" image={engine}>
                                <ServiceContent>
                                    <p>Wymiana rozrządów, uszczelek, naprawa głowic.</p>
                                </ServiceContent>
                            </Service>
                        </div>

                        <div className="column is-one-third">
                            <Service header="Układ kierowniczy" image={steeringWheel}>
                                <ServiceContent>
                                    <p>
                                        Wymiana przekładni kierowniczych, drążków kierowniczych, końcówek drążków, pomp
                                        wspomagania. Geometria kół.
                                    </p>
                                </ServiceContent>
                            </Service>
                        </div>

                        <div className="column is-one-third">
                            <Service header="Zawieszenie" image={wheels}>
                                <ServiceContent>
                                    <p>Wymiana wahaczy, sprężyn, amortyzatorów, tulei, przegubów, łożysk, sworzni.</p>
                                </ServiceContent>
                            </Service>
                        </div>

                        <div className="column is-one-third">
                            <Service header="Układ hamulcowy" image={brake}>
                                <ServiceContent>
                                    <p>Wymiana tarcz, klocków, szczęk, bębnów, cylinderków hamulcowych i zacisków,
                                        linek hamulca postojowego, test płynu hamulcowego.</p>
                                    <p>Test płynu hamulcowego.</p>
                                </ServiceContent>

                                <ServiceContent className="has-background-info has-text-centered">
                                    Po każdej naprawie jest sprawdzana skuteczność hamowania na urządzeniu rolkowym!
                                </ServiceContent>
                            </Service>
                        </div>

                        <div className="column is-one-third">
                            <Service header="Diagnostyka silnika" image={computerEngine}>
                                <ServiceContent>
                                    <p>Komputerowa diagnostyka silnika benzyna/diesel. Sprawdzanie parametrów, usuwanie
                                        usterek, kasowanie inspekcji olejowych, kodowania podzespołów, adaptacje,
                                        wymiana uszkodzonych czujników.</p>
                                </ServiceContent>
                            </Service>
                        </div>

                        <div className="column is-one-third">
                            <Service header="Układ napędowy" image={driveSystem}>
                                <ServiceContent>
                                    <p>Wymiana sprzęgieł, kół dwu masowych, przegubów i półosi napędowych, skrzyń
                                        biegów.</p>
                                </ServiceContent>
                            </Service>
                        </div>

                        <div className="column is-one-third">
                            <Service header="Regulacja świateł" image={lights}/>
                        </div>

                        <div className="column is-one-third">
                            <Service header="Elektronika / elektryka" image={electronic}/>
                        </div>
                    </div>
                </div>
            </section>

            <Question title="Nie znalazłes swojej usterki? Masz pytania?">
                <Link to="/kontakt" className="has-text-dark">Skontaktuj</Link> się z nami, na pewno pomożemy!
            </Question>
        </main>

        <Footer/>
    </Layout>
}
